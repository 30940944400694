@font-face {
    font-family: Reef;
    src: url('./resources/fonts/poppins/Poppins-Regular.ttf') format('truetype'),
    url('./resources/fonts/poppins/Poppins-Bold.ttf') format('truetype'),
    url('./resources/fonts/poppins/Poppins-Black.ttf') format('truetype'),
    url('./resources/fonts/poppins/Poppins-Medium.ttf') format('truetype'),
    url('./resources/fonts/poppins/Poppins-Light.ttf') format('truetype') {} }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

html {
    overflow-x: hidden; }

html, body {
    height: 100%;
    width: 100%; }

#root {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }

#components {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
