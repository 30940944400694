@import "./../../style";

@media screen and (min-width : 320px) {
    #welcoming_text_header {
        font-size: 72px; }

    #welcoming_text_subheader {
        font-size: 40px; }

    a {
        font-size: 20px; }

    #welcoming_text_container {
        width: 75%;
        text-align: left; } }

@media screen and (min-width : 1024px) {
    #welcoming_text_header {
        font-size: 64px; }

    #welcoming_text_subheader {
        font-size: 36px; }

    a {
        font-size: 16px; }

    #welcoming_text_container {
        width: 56%;
        text-align: center; } }

#home {
    overflow-x: hidden;
    position: relative;
    width: 100%;
    height: 800px;
    overflow-y: hidden;
    z-index: 1; }

#home_background {
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    z-index: 2; }

#home_animation {
    position: absolute;
    width: 100%;
    height: 800px;
    top: 0;
    left: 0;
    z-index: 3; }

#home_animation > * {
    position: absolute;
    top: 0;
    left: 0; }

/* Header Bar */
#welcoming_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 5; }

#container_elements {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > * {
        text-decoration: none;
        color: white;
        letter-spacing: 3px;
        word-spacing: 3px;
        font-size: 16px;
        font-family: $font_family;
        font-weight: 600;
        margin: 0 20px;
        opacity: 0.8; } }

#container_elements > *:hover {
    opacity: 1.0; }

#welcoming_text {
    position: absolute;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 4; }

#welcoming_text_container > * {
    margin: 24px 0; }

#welcoming_text_header {
    color: white;
    font-weight: 600;
    font-family: $font_family;
    font-size: 44px;
    letter-spacing: 5px;
    word-spacing: 5px; }

#welcoming_text_subheader {
    color: white;
    font-weight: 500;
    font-family: $font_family;
    font-size: 20px;
    letter-spacing: 3px;
    word-spacing: 3px; }
