@import "./../../style";

@media screen and (min-width: 320px) {
    #projects {
        height: 3250px; }

    .row_container {
        flex-direction: column; }

    .row {
        flex-direction: column; }

    .text_container {
        width: 70%;
        text-align: center; }

    .text_container_header {
        font-size: 32px; }

    .text_container_subheader {
        font-size: 12px; }

    .text_container_header {
        text-align: center !important; }

    .text_container_paragraph {
        text-align: justify !important;
        text-align-last: center; }

    .text_container_paragraph {
        font-size: 16px; }

    .img_container {
        width: 70%;
        height: auto; }

    .snippet-primary-normal {
        max-height : 180px;
        max-width : 90px; }

    .snippet-secondary-normal {
        max-height : 160px;
        max-width : 80px; }

    .snippet-primary-active {
        max-height: 240px;
        max-width: 120px; }

    .snippet-secondary-active {
        max-height: 220px;
        max-width: 100px; }

    .portfolio_bar {
        max-width: 120px; }

    .video-style-native {
        max-height: 220px;
        max-width: 120px; }

    .video-style-desktop {
        max-height: 210px;
        max-width: 320px; }

    .column_container {
        width: 100%; }

    .snippet-full {
        max-width: 100%;
        height: auto; } }

@media screen and (min-width: 480px) {

    .text_container_header {
        font-size: 40px; }

    .text_container_subheader {
        font-size: 17px; }

    .text_container_paragraph {
        font-size: 20px; }

    .snippet-primary-normal {
        max-height : 220px;
        max-width : 110px; }

    .snippet-secondary-normal {
        max-height : 180px;
        max-width : 90px; }

    .snippet-primary-active {
        max-height: 320px;
        max-width: 180px; }

    .snippet-secondary-active {
        max-height: 280px;
        max-width: 160px; }

    .portfolio_bar {
        max-width: 140px; }

    .video-style-native {
        max-height: 280px;
        max-width: 140px; }

    .video-style-desktop {
        max-height: 240px;
        max-width: 360px; } }

@media screen and (min-width: 1024px) {
    #projects {
        height: 3250px; }

    .row_container {
        flex-direction: row; }

    .row {
        flex-direction: row; }

    .img_container {
        width: 100%;
        height: auto; }

    .text_container {
        width: 100%;
        text-align: left; }

    .text_container_header {
        font-size: 48px; }

    .text_container_subheader {
        font-size: 14px; }

    .text_container_header, .text_container_paragraph {
        text-align: left !important;
        text-align-last: left; }

    .snippet-primary-normal {
        max-height : 440px;
        max-width : 220px; }

    .snippet-secondary-normal {
        max-height : 360px;
        max-width : 180px; }

    .snippet-primary-active {
        max-height: 520px;
        max-width: 260px; }

    .snippet-secondary-active {
        max-height : 440px;
        max-width : 220px; }

    .portfolio_bar {
        max-width: 280px; }

    .video-style-native {
        max-height : 560px;
        max-width : 280px; }

    .video-style-desktop {
        max-height : 440px;
        max-width : 540px; }

    .column_container {
        height: 100%; }

    .snippet-full {
        max-width: 80%;
        height: auto; } }


.snippet-primary-active, .snippet-primary-normal {
    z-index : 1;
    transform: translate(-50%, -50%); }

.snippet-secondary-active, .snippet-secondary-normal {
    z-index : 0;
    transform : translate(-50%, -50%); }

#projects {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%; }

#projects_full {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%; }

.animation {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }

.animation:after {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0, rgba(255, 255, 255, 1.0) 0%, rgba(255, 255, 255, 0.0) 15%, rgba(255, 255, 255, 0.0) 85%, rgba(255, 255, 255, 1.0) 100%); }

.project_scroll {
    display: flex;
    position: relative;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%; }

.project_fixed {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%; }

.progress {
    position: absolute;
    font-weight: bold;
    font-family: $font_family;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 50%); }

.project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: height 0.15s, width 0.15s; }

.project_small {
    position: absolute;
    width: 85%;
    height: 85%; }

.project_full {
    position: relative;
    width: 100%; }

.row_steps {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%; }

.row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 64px 16px; }

.row_background {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%; }

.row_container {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: 100%;

    > .column_container:last-of-type {
        z-index : 0;
        flex-direction: row; } }

.column_container {
    display: flex;
    z-index : 1;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1; }

.project_overview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 20px;
    position: absolute;
    cursor: pointer;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -150%); }

.project_other {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    top: 0;
    left: 0;
    padding: 32px 40px;
    z-index : 1; }

.project-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }

.brand_asset {
    width: 48px;
    height: auto; }

.project_action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row; }

.project_action_link {
    margin: 0 24px;
    color: white;
    opacity: 0.8;
    font-size: 18px;
    font-family: $font_family;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        opacity: 1.0; } }

.project_action_text {
    color: white;
    font-size: 20px;
    font-family: $font_family;
    font-weight: bold;
    animation: glow 1s ease-out alternate-reverse infinite;
    transform-origin: center;
    cursor: pointer; }

.project_action_text:hover {
    animation: none; }

@keyframes glow {
    0% {
        opacity: 0.6;
        transform: scale(1.0); }
    100% {
        opacity: 1.0;
        transform: scale(1.015); } }

.project_interaction_component {
    padding: 8px; }

.project_interaction_circle {
    width: 14px;
    height: 14px;
    border-radius: 12px;
    opacity: 0.4; }

.project_interaction_circle_primary {
    transform-origin: center;
    transform: scale(1.15);
    opacity: 1.0; }

.showcase_item {
    position: absolute;
    transition: opacity 4s ease-in-out;
    opacity: 0.8;
    top: 50%;
    left: 50%;
    width: 75px;
    height: 75px; }

.img_portfolio_asset {
    display: flex;
    position: relative; }

.img_portfolio {
    position: absolute;
    top: 50%;
    -webkit-transition: max-height 300ms ease-in-out, max-width 300ms ease-in-out, left 300ms ease-in-out;
    transition: max-height 300ms ease-in-out, max-width 300ms ease-in-out, left 300ms ease-in-out; }

.img_portfolio_post {
    position: absolute;
    visibility: hidden;
    top: 50%;
    z-index: -1; }

.portfolio_interaction {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.video_portfolio {
    position: relative; }

.container_pulse {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    width: 450px;
    height: 450px;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid white; }

.container_pulse_fast {
    animation: pulsating-fast 0.75s infinite; }

.container_pulse_slow {
    animation: pulsating-fast 1.5s infinite; }

@keyframes pulsating-fast {
    0% {
        transform: translate(-50%, -50%) scale(0, 0);
        opacity: 0.8; }

    100% {
        transform: translate(-50%, -50%) scale(1.0, 1.0);
        opacity: 0; } }

@keyframes pulsating-slow {
    0% {
        transform: translate(-50%, -50%) scale(0, 0);
        opacity: 0.8; }

    100% {
        transform: translate(-50%, -50%) scale(1.0, 1.0);
        opacity: 0; } }

.text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 85%; }

.text_container_header {
    color: white;
    font-family: Reef, sans-serif;
    font-weight: bold; }

.text_container_subheader {
    margin: 4px 0;
    color: white;
    opacity: 0.8;
    font-weight: 100;
    font-family: $font_family; }

.text_container_paragraph {
    margin: 20px 0;
    color: white;
    font-family: $font_family; }

.slash_style {
    position: relative;
    font-size: 12px;
    top: -0.5px;
    margin: 0 1px; }

.text_container_link {
    display: inline;
    margin: 20px 0;
    color: white;
    opacity: 0.8;
    font-size: 18px;
    font-family: $font_family;
    text-decoration: none;
    animation: glow-link 1s ease-out alternate-reverse infinite;

    &:hover {
        opacity: 1.0;
        animation: none; } }

@keyframes glow-link {
    0% {
        opacity: 0.6; }
    100% {
        opacity: 1.0; } }
