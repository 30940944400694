@import "./../../style";

#tools {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    left: 0;
    top: 50%;
    transform: translate(25%, -50%);
    z-index: 5;
    user-select: none;
    -webkit-tap-highlight-color: transparent; }

#tools > * {
    margin: 0 4px;
    user-select: none; }

.tools-header {
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    display: inline-block;
    font-size: 16px;
    letter-spacing: 0.4em;
    font-weight: bold;
    font-family: $font_family; }

.tools-utils {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column; }

.tools-utils:hover {
    opacity: 1.0;
    cursor: pointer; }

.tools-shape {
    width: 16px;
    height: 16px;
    border-radius: 2px; }

.tools-shape:hover {
    filter: drop-shadow(0 0 1px rgba(247, 202, 24, 0.25));
    transform-origin: 50% 50%;
    transform: scale(1.25, 1.25); }

.tools-shape-active {
    opacity: 1.0; }

.tools-shape-disabled {
    opacity: 0.4; }

.tools-shape-main {
    margin: 8px 0; }

.tools-shape-secondary {
    margin: 4px 8px; }
