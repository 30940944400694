@import "./../../style";

@media screen and (max-width: 480px) {
    #footer {
        height: 720px; }

    #land {
        width: 70%; }

    .contact_text {
        width: 75%;
        font-size: 22px; } }

@media screen and (min-width: 720px) {
    #footer {
        height: 840px; }

    #land {
        width: 35%; }

    .contact_text {
        font-size: 24px; } }

@media screen and (min-width: 1280px) {
    #footer {
        height: 900px; }

    #land {
        width: 25%; }

    .contact_text {
        font-size: 24px; } }

#footer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(0deg, rgba(252, 201, 185, 0.6) 50%, white 100%); }

#footer:before {
    content: '';
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 900px;
    width: 100%;
    background: radial-gradient(rgba(201, 55, 60, 0.075) 20%, rgba(255, 255, 255, 0) 50%); }

#footer_background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

#land {
    user-select: none;
    cursor: none;
    position: absolute;
    z-index: 0; }

.footer_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width : 100%;
    height: 100%;
    z-index: 2; }

.footer_row {
    display: flex;
    flex: 1; }

.footer_row:last-child {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: radial-gradient(farthest-corner at 50% 0%, rgba(210, 230, 249, 0.5), rgba(53,142,180, 0.9)); }

.footer_details {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 20%;
    transform: translate(0, -50%);

    > * {
        margin: 8px 0; } }

.contact_text {
    color: rgba(68, 108, 179, 1);
    text-align: center;
    font-weight: bold;
    font-family: $font_family; }

.contact_address {
    background-color: rgba(68, 108, 179, 0.9);
    border-radius: 4px;
    padding: 9px 15px;
    text-decoration: none;
    color : white;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-family: $font_family; }

.contact_address:hover {
    background-color: rgba(68, 108, 179, 1); }

.footer_info {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 8px 0;
    top: 100%;
    transform: translate(0, -100%);
    z-index: 4;

    > * {
        margin: 2px 0;
        font-weight: 600;
        color: rgba(68, 108, 179, 1);
        font-family: $font_family; } }

.note {
    font-size: 12px; }

.rights {
    font-size: 14px; }

#boat_1 {
    position: absolute;
    width: 35px;
    height: auto;
    top: 60%;
    left: 45%;
    animation: swim  ease-out 5s infinite alternate; }

#boat_2 {
    position: absolute;
    width: 30px;
    height: auto;
    top: 55%;
    left: 80%;
    animation: swim linear 5s infinite alternate-reverse; }

#boat_3 {
    position: absolute;
    width: 45px;
    height: auto;
    top: 65%;
    left: 15%;
    animation: swim linear 5s infinite alternate; }


#boat_4 {
    position: absolute;
    width: 15px;
    height: auto;
    top: 52%;
    left: 10%;
    animation: swim linear 5s infinite alternate-reverse; }

@keyframes swim {
    from {
        transform: translate(0, 0); }
    to {
        transform: translate(100px, 0); } }
